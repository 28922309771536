
export default {
    customProperties: null,
    synced: false,

    sync(router) {
        //console.log('SYNC', this.synced, window.ga, window.gtag);
        if (this.synced) return;
        if (!window.gtag) return;

        if (router.currentRoute) {
          window.gtag('set', 'page_path', router.currentRoute.path);
          window.gtag('event', 'page_view');          
        }
        
        router.afterEach(( to, from ) => {
          window.gtag('set', 'page_path', to.path);
          window.gtag('event', 'page_view');          
        });        
        this.synced = true;
    },

    event(eventName, options) {
        if (!this.synced) return;
        console.log('Analytics event:', eventName, options);
        window.gtag('event', eventName, options);
    },

    exception(message) {
        if (!this.synced) return;
        console.log('Analytics exception:', message);
        window.gtag('event', 'exception', {
            description: message,
            fatal: false,
        });
    },

    arrayEquals(array1, array2) {
        if (array1 == null && array2 == null) {
            return true;
        }
        return array1 != null && array2 != null && array1.length === array2.length && array1.every((value, index) => value === array2[index]);
    },
    enableCustomTrackers(self, properties) {
        if (this.arrayEquals(properties, this.customProperties)) {
            // nothing to do
            //console.log('[analytics] ignoring: ', properties, this.customProperties);
            return;
        }
        //console.log('[analytics] enabling properties: ', properties, this.customProperties);
        this.customProperties = properties;

        if (!this.gaEnabled()) {
            return;
        }

        for (const property of properties) {
            //console.log('[analytics] enabling property: ', property);
            window.gtag('config', property, {'send_page_view': true});
            window.enableAnalyticsProperty(property);
        }
    },
    disableCustomTrackers(self) {
        if (!this.gaEnabled() || this.customProperties == null || this.customProperties.length == 0){
            return;
        }

        //console.log('[analytics] disabling properties: ', this.customProperties);
        for (const property of this.customProperties) {
            //console.log('[analytics] disabling property: ', property);
            if (window.disableAnalyticsProperty) window.disableAnalyticsProperty(property);
        }
        this.customProperties = null;
        
    },
    gaEnabled() {
        if (!window.isAnalyticsEnabled){
            return;
        }
        //console.log('[analytics] ga enabled: ', window.isAnalyticsEnabled());
        return window.isAnalyticsEnabled();
    },
}
