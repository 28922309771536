<template>
  <v-navigation-drawer
    fixed
    temporary
    v-model="expanded"
    :right="$vuetify.rtl"
    @input="toggle"
    width="90%"
  >
    <v-list-item to="/">
      <v-list-item-title v-if="tenant.id === 'cofi'" class="title">
        <div><img src="/images/logo-cofi.png" alt="Logo CorporateFitness.app" width="200" class="mt-6" /></div>
      </v-list-item-title>
      <v-list-item-title v-else class="title">
        <div><img :src="tenant.logoUrl" alt="Logo sodisp" width="200" /></div>
        <!--<strong>sodisp</strong>.com-->
      </v-list-item-title>
      <v-list-item-content>
        <v-list-item-subtitle v-if="tenant.id === 'cofi'">
          Connected Corporate Fitness
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          Challenge Platform
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider dark class="my-4"/>
    <v-list dense>
      <template v-for="(item, i) in menu">
        <template v-if="item.items && item.items.length" >
          <v-list-item v-if="item.items && item.items.length" :key="i">
            <v-list-item-icon v-if="item.icon" class="me-4">
              <v-icon>fal {{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

      <div :set="submenus = item.items.filter(x => x.multi_column && x.items && x.items.length)" :set2="nonsubmenus = item.items.filter(x => !x.multi_column)" :key="`submenu_${i}`">
      <div v-if="submenus.length" class="pb-0 px-4">
      <v-row>
          <v-col xs="12" :sm="12 / submenus.length" v-for="(submenu, subi) in submenus" :key="subi">
              <h5 class="subtitle">
                <router-link v-if="submenu.path" class="no-decoration" :to="submenu.path">{{submenu.title}}</router-link>
                <span v-else>{{submenu.title}}</span>
              </h5>
              <v-list class="pb-0">
              <template v-for="(subitem, i) in submenu.items">
                  <v-list-item :key="i" link :to="isAbsoluteUrl(subitem.path) ? null : subitem.path" :href="isAbsoluteUrl(subitem.path) ? subitem.path : null" exact class="px-0">
                    <v-list-item-icon v-if="subitem.icon" class="me-4">
                      <v-icon>fal {{ subitem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                        {{ subitem.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </template>
              <!-- <v-divider />
              <v-list-item link class="px-0" :href="submenu.path">View All</v-list-item> -->
              </v-list>
          </v-col>
      </v-row>
      <v-divider v-if="nonsubmenus.length" />
      </div>
      <div v-if="nonsubmenus.length">
      <template v-for="(subitem, i) in nonsubmenus">
          <v-list-item :key="i" link :to="isAbsoluteUrl(subitem.path) ? null : subitem.path" :href="isAbsoluteUrl(subitem.path) ? subitem.path : null" exact>
            <v-list-item-icon v-if="subitem.icon" class="me-4">
              <v-icon>fal {{ subitem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="allow-wrap">
                  <span v-if="subitem.markdown" class="mb-0 markdown" v-html="$options.filters.markdown(subitem.markdown)"/>
                  <span v-else >{{ subitem.title }}</span>
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </template>
      </div>
      </div>

        <v-divider :key="`divider_${i}`" dark class="my-2" />
        </template>
        <v-list-item v-else :key="i" link :to="isAbsoluteUrl(item.path) ? null : item.path" :href="isAbsoluteUrl(item.path) ? item.path : null">
          <v-list-item-action v-if="item.icon" class="me-4">
            <v-icon>fal {{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item v-if="tenant.id === 'cofi'" class="mt-4">
        <v-btn color="primary" block to="/eventmanager/create">Create an Event</v-btn>
      </v-list-item>
    </v-list>

    <!--<template v-slot:append>
      <div class="pa-2">
        <v-btn color="primary" block to="/me">My Profile</v-btn>
      </div>
    </template>   -->  
  </v-navigation-drawer>    
</template>

<script>
import { mapGetters } from "vuex";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import menuJson from "@/data/cms/nav-menu-cofi.json";

export default {
  name: "CmsDrawer",
  props: {
    value: Boolean,
  },
  data() {
    return {
      menu: null,
      tenant: tenant,
      expanded: this.value,
    };
  },
  mounted() {
    this.menu = menuJson;//await cmsService.getData('nav-menu-cofi');
  },
  methods: {
    isAbsoluteUrl(path) { return path && path.startsWith('https://'); },
    toggle(value) {
      this.$emit('input', value);
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    value(newValue) {
      this.expanded = newValue;
    }
  }
};
</script>

<style scoped>
    i.v-icon { max-width: 24px; }
</style>