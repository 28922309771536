<template>
    <div ref="jsContainer"></div>
</template>

<script>
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ChatBot",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
      hasLoadedJs: false,
    };
  },
  mounted() {
      this.initChat(this.$route);
  },
  methods: {
      initChat(route) {
        return; // disabled for now
        if (tenant.id !== 'cofi') {
            return;
        }
        var newState = route && route.meta && route.meta.showChat
        //console.log('nav to', newState, 'cookies', window.analyticsEnabled);
        if (newState === 'show') {
            this.ensureScriptLoaded();
        }
        if (newState !== 'show' && newState !== 'unchanged') {
            if (window.$zoho) window.$zoho.salesiq.floatbutton.visible("hide"); 
        }
      },
      ensureScriptLoaded() {
          if (this.hasLoadedJs) {
            if (window.$zoho) window.$zoho.salesiq.floatbutton.visible("show"); 
          };
          //if (!window.analyticsEnabled) {return;}
          this.loadScript();
          this.hasLoadedJs = true;
      },
      loadScript() {
        let newScript = document.createElement('script');
        newScript.id = 'zsiqchat'
        newScript.type = 'application/javascript'
        newScript.innerText = 'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "9419af3fca57987d56209cc5eacb5f9db580fa4453f0b9bd8d5a1a0f395c7b03008a29ed0eeb3d87b05c3edd7a4385fe", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';
        this.$refs.jsContainer.appendChild(newScript);
      },
  },
  watch: {
    '$route' (to, from) {
        this.initChat(to);
    }
  }
};
</script>

<style lang="scss">
.zsiq_theme1 div.zsiq_cnt { font-family: 'roboto';}
</style>