import i18n from './i18n'
import Vue from "vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
import { Interval, DateTime } from "luxon";
const tenant = tenants.current();

const UnitType = Object.freeze({ DISTANCE: 1, ELEVATION: 2, NUMBER: 3 });
const Icons = Object.freeze(['alicorn', 'apple-crate', 'badger-honey', 'bat', 'carrot', 'cat', 'cat-space', 'cow', 'crow', 'deer', 'deer-rudolph', 'dog', 'dog-leashed', 'dove', 'dragon', 'duck', 'elephant', 'feather', 'feather-alt', 'fish', 'frog', 'hippo', 'horse', 'horse-saddle', 'kiwi-bird', 'leaf', 'leaf-maple', 'leaf-oak', 'lemon', 'monkey', 'narwhal', 'otter', 'paw', 'paw-alt', 'paw-claws', 'pegasus', 'pepper-hot', 'pig', 'pumpkin', 'rabbit', 'rabbit-fast', 'ram', 'seedling', 'sheep', 'skull-cow', 'snake', 'spider', 'spider-black-widow', 'squirrel', 'turtle', 'unicorn', 'whale', 'wheat']);

export default {
    UnitType: UnitType,
    Icons: Icons,
    ValidationRules: {
      name: [
        v => !!v || "Please enter the name",
      ],
    },

    prefixSelectListWith(array, defaultItem) {
      var copy = [...array];
      copy.unshift(typeof defaultItem === 'string' ? {text: defaultItem, type: null, value: null} : defaultItem);
      return copy;
    },

    displayText(lookupListName, type, defaultText) {
      defaultText = defaultText || '';
      if (Array.isArray(lookupListName)) {
        return (lookupListName.find(x => x.type == type)||{text: defaultText}).text;
      }
      if (siteData[lookupListName]) {
        return (siteData[lookupListName].find(x => x.type == type)||{text: defaultText}).text;
      }
      return defaultText;
    },

    toastResponse(self, syncModel, okMessage) {
      if (syncModel.status == 'OK'){
        self.$toast.success(syncModel.msg || okMessage || 'All good!');
      }
      else if (syncModel.status == 'INFO'){
        self.$toast.info(syncModel.msg);
      }
      else if (syncModel.status == 'ERROR'){
        self.$toast.error(syncModel.msg);
      }
    },
    getGradientColor(index) {
      //const colors = ['gradient lighten-1', 'gradient', 'gradient darken-1', 'header lighten-1', 'header', 'header darken-1'];
      if (tenant.id == 'fitmap') {
        const colors = ['orange lighten-2', 'orange lighten-1', 'orange', 'orange darken-1', 'orange darken-2', 'primary', 'primary darken-1'];
        return colors[index % colors.length];
      }
      if (tenant.isDefault) {
        const colors = ['gradient lighten-1', 'gradient', 'orange', 'orange darken-1', 'header', 'header darken-1'];
        return colors[index % colors.length];
      }
      const colors = ['primary lighten-2', 'primary lighten-1', 'primary', 'primary darken-1'];
      return colors[index % colors.length];
    },
    randomId() {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      const id = Math.random().toString(36).substr(2, 9);
      // return in format abcd-efghi
      return `${id.substr(0, 4)}-${id.substr(4)}`;

      // note: below wasn't very random at all
      //return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      return 'yyy-yyy'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    getUnitType(metricType) {
      return metricType == 'IMPERIAL' ? 'mi' : 'km';
    },
    distanceUnitFromCountry(countryCode) {
      if (countryCode == 'US' || countryCode == 'GB' || countryCode == 'JP') {
        return  'IMPERIAL';
      }
      return 'METRIC';
    },
    canApplyTemplateToLeaderboard(event, race) {
      return ['DISTANCE', 'STEPS', 'TIME'].some(x => x == race.scoring)
          && (race.dist || race.collective_goal);
    },
    getGenderForDisplay(value, fullText) {
      if (value === 'M') {
        return fullText ? i18n.t('shared.gender-m-desc') : i18n.t('shared.gender-m');
      }
      if (value === 'F') {
        return fullText ? i18n.t('shared.gender-f-desc') : i18n.t('shared.gender-f');
      }
      if (value === 'O') {
        return fullText ? i18n.t('shared.gender-o-desc') : i18n.t('shared.gender-o');
      }
      if (value === 'U') {
        return fullText ? i18n.t('shared.gender-u-desc') : i18n.t('shared.gender-u');
      }
      return null;
    },
    isWaterBasedSport(type) {
      return ['SWIMMING', 'ROWING', 'WATER_SPORTS', 'KAYAKING', 'CANOEING', 'PADDLEBOARDING'].some(x => x === type);
    },
    getActivityIcon(type) {
      if (type === 'RUNNING' || type === 'INDOOR_RUNNING') {
        return 'fa-running';
      }
      if (type === 'CYCLING' || type === 'INDOOR_CYCLING') {
        return 'fa-biking';
      }
      if (type === 'WALKING' || type === 'HIKING') {
        return 'fa-walking';
      }
      if (type === 'STAIR_CLIMBING') {
        return 'fa-sort-amount-up-alt fa-flip-horizontal';
      }
      if (type === 'SWIMMING') {
        return 'fa-swimmer fa-flip-horizontal';
      }
      if (type === 'RUN_WALK') {
        return 'fa-shoe-prints';
      }
      if (type === 'WATER_SPORTS') {
        return 'fa-water';
      }
      if (type === 'ROWING') {
        return '$vuetify.icons.rowing';
      }
      if (type === 'YOGA') {
        return '$vuetify.icons.yoga';
      }
      if (type === 'MIND_BODY') {
        return '$vuetify.icons.yoga';
      }
      if (type === 'ELLIPTICAL' || (tenant.id =='100kmc' && type == 'OTHER')) {
        return '$vuetify.icons.elliptical';
      }
      if (type === 'WEIGHT_LIFTING') {
        return 'fa-dumbbell';
      }
      if (type === 'GOLF') {
        return 'fa-golf-ball';
      }
      if (type === 'PILATES') {
        return '$vuetify.icons.pilates';
      }
      if (type === 'PADDLEBOARDING') {
        return '$vuetify.icons.paddleboarding';
      }
      if (type === 'KAYAKING' || type === 'CANOEING' || type === 'PADDLEBOARDING') {
        return 'fa-water';
      }
      if (type === 'BACKCOUNTRY_SKIING' || type === 'NORDIC_SKIING') {
        return 'fa-skiing-nordic';
      }
      if (type === 'SNOWBOARDING') {
        return 'fa-snowboarding';
      }
      if (type === 'ALPINE_SKIING') {
        return 'fa-skiing';
      }
      if (type === 'INLINE_SKATING') {
        return 'fa-skating';
      }
      if (type === 'ICE_SKATING') {
        return 'fa-ice-skate';
      }
      if (type === 'WHEELCHAIR') {
        return 'fa-wheelchair';
      }
      if (type === 'MULTI_SPORT') {
        return 'fa-triangle';
      }
      if (type === 'VOLUNTEERING') {
        return 'fa-hands-helping';
      }
      if (type === 'TENNIS') {
        return 'fa-racquet';
      }
      if (type === 'TEAM_SPORTS') {
        return 'fa-users';
      }
      if (type === 'HORSEBACK_RIDING') {
        return 'fa-horse-saddle';
      }
      if (type === 'OTHER') {
        return 'fa-ellipsis-h';
      }
      if (type === 'DAILY_SUMMARY') {
        return 'fa-calendar-day';
      }
      return 'fa-ellipsis-h';
    },
    getGoalLabel(race) {
      if (race.scoring == "CUSTOM"){
        return race.custom || 'Points';
      }
      return i18n.t('events.rules.scoring-methods-short.'+race.scoring);
    },
    isCumulativeScoring(race) {
      return this.isCumulativeScoringMethod(race.scoring);
    },
    isCumulativeScoringMethod(scoring) {
      return ["DISTANCE", "ELEVATION", "ELEVATION_LOSS", "TIME", "CALORIES", "STAIRS", "STEPS", "CUSTOM"].some(x => x === scoring);
    },
    getAwardPositionColor(pos) {
      if (pos === 1) return '#d6af36';
      if (pos === 2) return '#a7a7ad';
      if (pos === 3) return '#a77044';
      return 'grey';
    },
    getScoreTooltipForDisplay(options, race, result, unitOrEvent) {
      const value = result.score_value;
      if (race.scoring == 'BADGES' && race.badge_scoring == 'IMPROVEMENT') {
        if (value ==  2) return '⇈ • Strong improvement over last period';//'⏫';
        if (value ==  1) return '↑ • Improvement over last period';//'🔼';
        if (value ==  0) return '± • Similar to last period';//'▶️';
        if (value == -1) return '↓ • Slightly less compared to last period';//'🔽';
        if (value == -2) return '⇊ • Significantly less compared to last period';//'⏬';
      }
      return this.getValueForDisplay(options, race, result, unitOrEvent);
    },
    getStatusForDisplay(options, status) {
      return i18n.t('results.status.'+status);
    },

    getPaceOrSpeed(options, distance, time, unitType, activityType) {
      //console.log('/// paceOrSpeed', distance, time, unitType, activityType);
      /*if (activityType && ['STAIR_CLIMBING'].some(x => x === activityType)) {
        var speed = ( distance) / ( time / 60.0 )
        return options.filters.speed(speed, unitType, activityType);
      }*/
      if (activityType && ['STAIR_CLIMBING', 'CYCLING', 'INDOOR_CYCLING'].some(x => x === activityType)) {
        var speed = ( distance / 1000.0 ) / ( time / 3600.0 )
        return options.filters.speed(speed, unitType, activityType);
      }
      var pace = time / ( distance / 1000.0 );
      return options.filters.pace(pace, unitType, activityType);
    },
    
    getValueForDisplay(options, race, result, unitOrEvent) {
      var unit = null;
      var event = null;
      if (typeof unitOrEvent === 'string' || unitOrEvent instanceof String) {
        unit = unitOrEvent;
      }
      else if (unitOrEvent != null) {
        event = unitOrEvent;
        unit = event.unit;
      }

      const value = result.score_value;
      if (!race){ 
        return value;
      }
      var fullTime = race.type === 'EXTERNAL';
      //console.log('getValueForDisplay', race.scoring, value, unit);

      if (race.scoring == "TILES" && (!race.tile_scoring || race.tile_scoring == 'COVERED_TILES' || race.tile_scoring == 'OWNED_TILES')){
        return value; // number of tiles
      }
      if (race.scoring == 'TILES' && race.tile_scoring == 'SECONDARY_SCORING') {
        return this.getSecondaryValueForDisplay(options, race, result.secondary_value, unit);
      }
      if (race.scoring == 'TIERS') {
        const tier = race.tiers && race.tiers.find(x => x.value == value);
        return tier && tier.name;
      }
      if (race.scoring == 'BADGES' && race.badge_scoring == 'DAILY_TARGET') {
        return `${value} day(s)`;
      }
      if (race.scoring == 'BADGES' && race.badge_scoring == 'WEEKLY_TARGET') {
        return `${value} week(s)`;
      }
      if (race.scoring == 'BADGES' && race.badge_scoring == 'MANUAL_CLAIM') {
        return `${value} points`;
      }
      if (race.scoring == 'BADGES' && race.badge_scoring == 'IMPROVEMENT') {
        if (value ==  2) return '⇈';//'⏫';
        if (value ==  1) return '↑';//'🔼';
        if (value ==  0) return '±';//'▶️';
        if (value == -1) return '↓';//'🔽';
        if (value == -2) return '⇊';//'⏬';
      }
      if (race.scoring == "ELEVATION" || race.scoring == "ELEVATION_LOSS"){
        return options.filters.elevation(value, unit);
      }
      if (race.scoring == "DISTANCE"){
        if (tenant.id == '100kmc') { return `${options.filters.int(value)} m.`; }
        return options.filters.distance(value, unit, false, race.activity_types);
      }
      else if (["RESULT", "TRACK"].some(x => x === race.scoring)){
        if (fullTime) {
          return options.filters.time(-1*value);
        }
        return options.filters.duration(-1*value, /*compact:*/false);
      }
      else if (race.scoring == "TIME"){
        if (fullTime) {
          return options.filters.time(value);
        }
        return options.filters.duration(value, /*compact:*/false);
      }
      else if (race.scoring == "CALORIES"){
        return `${value} kcal`;
      }
      else if (race.scoring == "STEPS"){
        if (event && event.result_format === 'POINTS') {
          return i18n.t('results.formatting.points', { value: options.filters.int(value)});
        }
        return i18n.t('results.formatting.steps', { value: options.filters.int(value)});
      }
      else if (race.scoring == "STAIRS"){
        return `${value} ${i18n.t('results.cols.stairs').toLowerCase()}`;
      }
      else if (["BADGES", "TRAINING_PLAN"].some(x => x === race.scoring)){
        return `${value}`;
      }
      else if (race.scoring == "CUSTOM"){
        if (!race.custom && race.secondary_scoring == 'DISTANCE') {
          return options.filters.distance(value, unit, false, race.activity_types);
        }
        return `${value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 2})} ${(race.custom||i18n.t('profile.activities.points')).toLowerCase() }`;
      }
      return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 2});
    },
    getSecondaryValueForDisplay(options, race, value, unit) {
      if (race.scoring == "TIERS" && race.custom){
        return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' ' + race.custom;
      }
      if (race.secondary_scoring == "DURATION"){
        return options.filters.duration(value, /*compact:*/false);
      }
      if (race.secondary_scoring == "DURATION_FASTEST"){
        return options.filters.duration(-1*value, /*compact:*/false);
      }
      if (race.secondary_scoring == "DISTANCE"){
        return options.filters.distance(value, unit, false, race.activity_types);
      }
      if (race.secondary_scoring == "ELEVATION"){
        return options.filters.elevation(value, unit);
      }
      if (race.secondary_scoring == "LAST_ACTIVITY_DATE"){
        return options.filters.localDate(value, 'L');
      }
      if (race.secondary_scoring == "STEPS"){
        return i18n.t('results.formatting.steps', { value: options.filters.int(value)});
      }
      if (race.secondary_scoring == "VALUE"){
        return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0});
      }
      if (race.secondary_scoring == null && race.scoring == "BADGES"){
        return options.filters.duration(-1*value, /*compact:*/false);
      }
      return `${value}`;
    },
    getGoalDistanceForDisplay(options, race, value, unitOrEvent) {
      var unit = null;
      var event = null;
      if (typeof unitOrEvent === 'string' || unitOrEvent instanceof String) {
        unit = unitOrEvent;
      }
      else if (unitOrEvent != null) {
        event = unitOrEvent;
        unit = event.unit;
      }

      if (!value || value === 0) {
        return i18n.t('events.rules.open-goal');
      }
      if (race.scoring == "ELEVATION" || race.scoring == "ELEVATION_LOSS"){
        return options.filters.elevation(value, unit);
      }
      else if (race.scoring == "TIME"){
        return options.filters.duration(value, /*compact:*/true);
      }
      else if (race.scoring == "CALORIES"){
        return `${value} kcal`;
      }
      else if (race.scoring == "STEPS"){
        if (event && event.result_format === 'POINTS') {
          return i18n.t('results.formatting.points', { value: options.filters.int(value)});
        }
        return i18n.t('results.formatting.steps', { value: options.filters.int(value)});
      }
      else if (race.scoring == "STAIRS"){
        return `${value} ${i18n.t('results.cols.stairs').toLowerCase()}`;
      }
      else if (race.scoring == "BADGES"){
        return `${value} ${i18n.t('results.cols.badges').toLowerCase()}`;
      }
      else if (race.scoring == "TRAINING_PLAN"){
        return `${value} ${i18n.t('results.cols.workouts').toLowerCase()}`;
      }
      else if (race.scoring == "TILES"){
        var scoring = siteData.tile_scoring_methods.find(x => x.type == (race.tile_scoring || 'COVERED_TILES'));
        
        return `${scoring.text}, ${value} tiles`;
      }
      else if (race.scoring == "CUSTOM"){
        return `${value} ${(race.custom||i18n.t('profile.activities.points')).toLowerCase() }`;
      }
      return options.filters.distance(value, unit, false, race.activity_types);
    },
    getBadgeValueForDisplay(options, event, race, format, value) {
      var unit = event.unit;
      var fullTime = race.type === 'EXTERNAL';
      
      if (format == "ELEVATION" || format == "ELEVATION_LOSS"){
        return options.filters.elevation(value, unit);
      }
      else if (format == "DURATION"){
        if (fullTime) {
          return options.filters.time(value);
        }
        return options.filters.duration(value, /*compact:*/true);
      }
      else if (format == "SPEED"){
        return options.filters.speed(value, unit);
      }
      else if (format == "STEPS"){
        if (event && event.result_format === 'POINTS') {
          return i18n.t('results.formatting.points', { value: options.filters.int(value)});
        }
        return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0});
      }
      else if (format == "PACE"){
        return options.filters.pace(value, unit);
      }
      else if (format == "VALUE"){
        return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0});
      }
      else if (format == "PERCENTAGE"){
        return '✔'; //todo
      }
      else if (format == "ON_OFF"){
        return '✔';
      }
      return options.filters.distance(value, unit);
    },
    getDistanceForDisplay(options, race, value, unit) {
      if (value === undefined) {
        return '-';
      }
      if (race.type == "STAIR_CLIMBING"){
        return `${value} ${i18n.t('results.cols.stairs').toLowerCase()}`;
      }
      return options.filters.distance(value, unit, false, race.activity_types);
    },
    getScoringIcon(scoringMethod) {
      if (scoringMethod === 'ELEVATION' || scoringMethod == 'ELEVATION_LOSS') {
        return 'fa-mountain';
      }
      if (scoringMethod === 'RESULT') {
        return 'fa-stopwatch';
      }
      if (scoringMethod === 'DISTANCE') {
        return 'fa-ruler';
      }
      if (scoringMethod === 'TRACK') {
        return 'fa-route';
      }
      if (scoringMethod === 'STEPS') {
        return 'fa-shoe-prints';
      }
      if (scoringMethod === 'CALORIES') {
        return 'fa-fire-alt';
      }
      if (scoringMethod === 'TIME') {
        return 'fa-hourglass-half';
      }
      if (scoringMethod === 'STAIRS') {
        return 'fa-sort-amount-up-alt fa-flip-horizontal';
      }
      if (scoringMethod === 'BADGES') {
        return 'fa-shield';
      }
      if (scoringMethod === 'TRAINING_PLAN') {
        return 'fa-calendar-alt';
      }
      if (scoringMethod === 'TILES') {
        return 'fa-hexagon fa-rotate-90';
      }
      if (scoringMethod === 'TIERS') {
        return 'fa-layer-group';
      }
      if (scoringMethod === 'CUSTOM') {
        return 'fa-cogs';
      }
    },
    getProviderIcon(value) {
      if (value === 'manual_entry' || value === 'quick_entry') {
        return 'fa-keyboard';
      }
      if (value === 'result') {
        return 'fa-check-double';
      }
      if (value === 'import') {
        return 'fa-file-import';
      }
      if (value === 'gpx') {
        return 'fa-route';
      }
      if (value === 'strava') {
        return 'fab fa-strava';
      }
      if (value === 'oura') {
        return 'fa-ring fa-rotate-90';
      }
      if (value === 'whoop') {
        return 'fa-watch-fitness';
      }
      if (value === 'garmin' || value === 'polar' || value === 'suunto' || value === 'coros' || value === 'underarmour') {
        return 'fa-watch';
      }
      if (value === 'fitbit' || value === 'apple-watch' || value === 'samsung-health') {
        return 'fa-watch-fitness';
      }
      if (value === 'app' || value === 'google-fit' || value === 'google-hc') {
        return 'fa-mobile';
      }
      if (value === 'concept2' || value === 'asdeporte') {
        return 'fa-sync';
      }
      return 'fa-ellipsis-h';
    },
    getActivityTypeLabel(type, event, race) {
      if (type === 'CUSTOM'){
        if (!event || !race) {
          return 'Custom';
        }
        //console.log('/// type', type, event.custom_activity_types, race.custom_activity_type_ids);
        return race.custom_activity_type_ids.map(id => event.custom_activity_types.find(x => x.id == id).name).join(', ');
      } 
      if (tenant.activityTypes && tenant.activityTypes.hasOwnProperty(type) && tenant.activityTypes[type]) {
        return tenant.activityTypes[type];
      }
      return i18n.t('profile.activities.types.'+type);
    },
    showRegistrationOptions(event) {
      var now = DateTime.now();
      var till = DateTime.fromISO(event.till);
      var regTill = event.reg_till ? DateTime.fromISO(event.reg_till) : null;
      //console.log('showRegistrationOptions', event.till, event.reg_till, event.reg_url);
      return event && event.reg_url && (regTill == null ? now < till : now < regTill);
    },
    isActiveEvent(event) {
      return this.isTimeInBetween(event.from, event.till);
    },
    isActiveRace(event, race) {
      return this.isTimeInBetween(race.from || event.from, race.till || event.till);
    },
    isFutureEvent(event) {
      var from = DateTime.fromISO(event.from);
      var now = DateTime.now();
      return from > now;
    },
    isPastEvent(event) {
      var till = DateTime.fromISO(event.till);
      var now = DateTime.now();
      return till < now;
    },
    isFutureTimestamp(isoDate) {
      var val = DateTime.fromISO(isoDate);
      var now = DateTime.now();
      return val > now;
    },
    isTimeInBetween(fromString, tillString) {
      var from = DateTime.fromISO(fromString);
      var till = DateTime.fromISO(tillString);
      var now = DateTime.now();
      return now > from && now < till;
    },
    getStartDurationForEvent(event) {
      var from = DateTime.fromISO(event.from);
      var now = DateTime.now();
      var interval = from < now ? Interval.fromDateTimes(from, now) : Interval.fromDateTimes(now, from);
      //console.log('getStartDurationForEvent', interval, interval.length('hours'));
      return interval.length('seconds');
    },
    getEndDurationForEvent(event) {
      var till = DateTime.fromISO(event.till);
      var now = DateTime.now();
      var interval = till < now ? Interval.fromDateTimes(till, now) : Interval.fromDateTimes(now, till);
      //console.log('getStartDurationForEvent', interval, interval.length('hours'));
      return interval.length('seconds');
    },
    getDurationForEvent(event) {
      var from = DateTime.fromISO(event.from);
      var till = DateTime.fromISO(event.till);
      var interval = Interval.fromDateTimes(from, till);
      //console.log('getStartDurationForEvent', event.from, event.till, interval, interval.length('hours'), interval.length('days'));
      return interval;
    },
    getStartDurationForRace(event, race) {
      var from = DateTime.fromISO(race.from || event.from);
      var now = DateTime.now();
      var interval = from < now ? Interval.fromDateTimes(from, now) : Interval.fromDateTimes(now, from);
      return interval;//.length('seconds');
    },
    getDurationForRace(event, race) {
      var from = DateTime.fromISO(race.from || event.from);
      var till = DateTime.fromISO(race.till || event.till);
      var interval = Interval.fromDateTimes(from, till);
      //console.log('getStartDurationForEvent', event.from, event.till, interval, interval.length('hours'), interval.length('days'));
      return interval;
    },

    isDarkColor(color) {
      //console.log('DARK?', color);
      // Variables for red, green, blue values
      var r, g, b, hsp;
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
          // If RGB --> store the red, green, blue values in separate variables
          color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
          if (color == null) {
            return false;
          }
          
          r = color[1];
          g = color[2];
          b = color[3];
      } 
      else {
          // If hex --> Convert it to RGB: http://gist.github.com/983661
          color = +("0x" + color.slice(1).replace( 
          color.length < 5 && /./g, '$&$&'));
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
      }
      
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
      );

      // Using the HSP value, determine whether the color is light or dark
      return hsp<=150;// was: 127.5;
    },
    parseColorToArray(color) {
      var r, g, b;
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
        r = color[1];
        g = color[2];
        b = color[3];
      } 
      else {
          // If hex --> Convert it to RGB: http://gist.github.com/983661
          if (color.length == 9) {
            // #rrggbbaa
            color = color.slice(0, 7);
          } 
          color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
      }
      return [r,g,b];
    },
    getColorVariant(baseColor, weight) {
      if (!baseColor) return null; 
      const isDark = this.isDarkColor(baseColor);
      return this.lightenDarkenColor(baseColor, (isDark ? -1 : 1) * (weight||50));
    },
    getRaceColor(event, index) {
      if (!event) return null; 
      const baseColor = event.color;
      if (!baseColor) return null; 
      const targetColor = this.getColorVariant(baseColor);
      const stop = index / (event.races.length-1);
      const color = this.getGradientColorAt(baseColor, targetColor, stop);
      //console.log('Color',baseColor,' dark:', isDark, this.parseColorToArray(baseColor), stop, this.parseColorToArray(targetColor), color);
      return color;
    },
    getGradientColorAt(colorCode1, colorCode2, weight) {
      if (isNaN(weight)) weight = 0;
      var w1 = 1-weight;
      var w2 = weight;
      var color1 = this.parseColorToArray(colorCode1);
      var color2 = this.parseColorToArray(colorCode2);
      return `rgba(${Math.round(color1[0] * w1 + color2[0] * w2)}, ${Math.round(color1[1] * w1 + color2[1] * w2)},${Math.round(color1[2] * w1 + color2[2] * w2)})`;
    },
    lightenDarkenColor(col, amt) {
      var usePound = false;
      if (col.length == 9) {
        // #rrggbbaa
        col = col.slice(0, 7);
      } 
      if (col[0] == "#") {
          col = col.slice(1);
          usePound = true;
      }
   
      var num = parseInt(col,16);
   
      var r = (num >> 16) + amt;
   
      if (r > 255) r = 255;
      else if  (r < 0) r = 0;
   
      var b = ((num >> 8) & 0x00FF) + amt;
   
      if (b > 255) b = 255;
      else if  (b < 0) b = 0;
   
      var g = (num & 0x0000FF) + amt;
   
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
   
      return (usePound?"#":"") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
    },

    loadExternalScript (scriptUrl) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      document.body.appendChild(script);
      
      return new Promise((res, rej) => {
        script.onload = function() {
          res();
        }
        script.onerror = function () {
          rej();
        }
      });
    },

    async emitPromised(self, method, ...params){
      let listener = self.$listeners[method] || self.$attrs[method] || self[method]
      if(listener){
          let res = await listener(...params)
          return res === undefined || res
      }
      return false
    },

    copyToClipboard(str, toaster) {
      console.log('Copy-ing', str);
      if (navigator.clipboard) {
        // try the new way when supported
        navigator.clipboard.writeText(str).then(
          function(){
            if (toaster) toaster.info("Text copied to clipboard");
          })
        .catch(function(ex) {
          console.error('Error copying text', ex);
        });
        return;
      }
      // fall-back legacy copy
      const el = document.createElement('textarea');  // Create a <textarea> element
      el.value = str;                                 // Set its value to the string that you want copied
      el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
      el.style.position = 'absolute';                 
      el.style.left = '-9999px';                      // Move outside the screen to make it invisible
      document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
      const selected =            
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
          ? document.getSelection().getRangeAt(0)     // Store selection if found
          : false;                                    // Mark as false to know no selection existed before
      el.select();                                    // Select the <textarea> content
      try {
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        if (toaster) toaster.info("Text copied to clipboard");
      }
      catch (ex) {
        console.error(ex);
      }
      document.body.removeChild(el);                  // Remove the <textarea> element
      if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
      }
    },
}
