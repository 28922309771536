import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getBrowserLocale from "@/util/i18n/browser-locales"
import getSupportedLocales from "@/util/i18n/supported-locales"
import VueCookies from 'vue-cookies'

Vue.use(VueI18n)

function getStartingLocale() {
  const cookieLocale = VueCookies.get('locale');
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  const urlLocale = new URLSearchParams(window.location.search).get("lang");
  var locale = urlLocale || cookieLocale || browserLocale;
  if (getSupportedLocales(locale)) {
    console.log('Loading locale:', locale, '- Cookie:', cookieLocale, 'url:', urlLocale, 'browser:', browserLocale);
    VueCookies.set('locale', locale, '30d');
    return locale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: getStartingLocale() || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
